import React from 'react';
import './ComingSoon.css';
import './button.css'
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="title">Coming Soon! <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: '10px' }} /></h1>
      <p className="subtitle">We're working hard to bring you the best experience possible.  <FontAwesomeIcon icon={faCheck} /> </p>
      <div className="countdown-container">
      </div>
      <Link to="/" className="home-button">
        <button className="button">Home</button>
      </Link>
    </div>
  );
};

export default ComingSoon;